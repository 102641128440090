@charset "utf-8";

// Colors
$text-off-white: #e9dce9;
$text-color: #5b5467;
$text-color-lighter: #7c738c;
$text-color-adventure: #4a4a4a;
$text-logo-dark-teal: #63c3c4;
$text-sproutel-blue: #7ecddf;
$header-yellow: #f8d277;
$header-teal: #357e80;
$play-button: #aed8d9;
$purrble-teal: #70cacb;
$button-order-fill: #ffc852;
$button-order-border: #c3a459;
$button-dropshadow: #9da0a6;
$footer-color: #5b5467;
$background-grey: #f8f8f8;
$adventure-header: #3e8b94;
$adventure-minimap-background: #fff1d8;
$adventure-footer: #317077;
$adventure-footer-text: #cfe5e7;

@font-face {
  font-family: CandelaBook;
  font-weight: 200;
  src: url("/fonts/CandelaBook.otf");
}

@font-face {
  font-family: CandelaBook;
  font-weight: 600;
  src: url("/fonts/CandelaBold.otf");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: 200;
  src: url("/fonts/SourceSansPro-Light.ttf");
}

@font-face {
  font-family: SourceSansPro;
  font-weight: bold;
  src: url("/fonts/SourceSansPro-Bold.ttf");
}

// Update Bulma's global variables
$family-sans-serif: "CandelaBook", "SourceSansPro", "Arial", sans-serif;
// $grey-dark: $brown;
// $grey-light: $beige-light;
// $primary: $purple;
// $link: $pink;
// $widescreen-enabled: false;
// $fullhd-enabled: false;

@import "../../node_modules/bulma/bulma";

// https://github.com/jgthms/bulma/issues/1561
// Using .columns introduces horizontal scroll
* {
  box-sizing: border-box;
}
.columns,
.columns:last-child {
  margin: 0;
}

h1.title,
h2.title,
h2.subtitle,
h3.title,
h3.subtitle,
h4.title,
h4.subtitle,
li,
p {
  color: $text-color;
}

p,
ul,
li {
  font-size: 1.4rem;
}

@include mobile {
  p,
  ul,
  li {
    font-size: 1.2rem;
  }
}

h1.title {
  font-size: 4.4rem;
}

// TODO check this
@include mobile {
  h1.title {
    font-size: 2.4rem;
  }
  h3.title {
    font-size: 2rem;
  }
  // h2.title.is-2 {
  //     font-size: 1.4rem;
  // }
}

section {
  padding-top: 4rem;
  padding-bottom: 4rem;
  @include mobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.is-vmiddle {
  display: flex;
  align-items: center;
}

.is-vmiddle-tablet {
  @include tablet {
    display: flex;
    align-items: center;
  }
}

@include mobile {
  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
}

section.videos {
  background-color: #f9f9f9;
  // padding-top: 70px; // Header
  padding-top: 0;
  padding-bottom: 0rem;
  @include mobile {
    padding-left: 0;
    padding-right: 0;
  }

  div.wistia_channel {
    min-height: 60vh !important; // Default is 100vh
    font-family: $family-sans-serif !important;
    // TODO set different heights for mobile, desktop, etc
  }

  div.w-gallery-view {
    background: #fff !important;
  }

  div.w-gallery-view__hero-content {
    @media (max-width: 600px) {
      text-align: center !important;
    }
  }

  div.w-gallery-view__gallery-card-cover {
    width: 100% !important;
    // White overlay
    // background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 120px, rgba(255, 255, 255, 1.0)) !important; // Default rgba w/ a of 0.667
    // Black overlay
    // background-image: linear-gradient(to top, rgb(255, 255, 255) 10%, rgba(0, 0, 0, 0) 60%),
    // linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 20%) !important;

    // Lightly darkened
    background-image: linear-gradient(to top, rgba(255, 255, 255, 1) 8%, rgba(0, 0, 0, 0.2) 60%) !important;
  }

  button.w-channel-big-button {
    background-color: #aed8d9 !important;
    border-radius: 0.8rem !important;
    padding: 1rem 2rem 1rem 2rem !important;
    margin-bottom: 1rem !important;
    line-height: 1.1em !important;
    filter: drop-shadow(0.1rem 0.1rem 0.1rem $button-dropshadow) !important;
    color: #357e80 !important;
    // font-size: 1.4rem !important; // This causes vertical height problems
    svg {
      fill: #357e80 !important;
    }
  }

  div.w-gallery-view__hero-content-wrapper {
    justify-content: flex-end !important;
    min-height: 600px !important;
  }

  div.w-gallery-view__hero-content {
    h1 {
      font-family: $family-sans-serif !important;
      font-weight: 600 !important;
      font-size: 2.5rem !important;
      color: #fff !important;
      text-shadow: none !important;
    }

    p {
      color: #fff !important;
      text-shadow: none !important;
    }
  }

  div.w-gallery-view__description {
    font-family: $family-sans-serif !important;
    font-size: 2.5rem !important;
    line-height: 2.9rem !important;
    font-weight: 600;
    // color: #474351 !important;
    // Load color with transition
    color: #fff !important;
    text-shadow: none !important;
  }

  div.w-gallery-view__video-cards {
    h3 {
      font-family: $family-sans-serif !important;
      font-size: 1.4rem !important;
      // color: #2f2c34 !important;
      color: $text-color !important;
      text-align: center !important;
    }
  }
}

div.w-popover-overlay__non-media-content {
  font-family: $family-sans-serif !important;
  ul {
    li {
      font-size: 1.4rem !important;
    }
  }
}

section.purrble-is-a-companion {
  padding-top: 0; // Header
  padding-bottom: 0; // FIXME check on very large screen

  // Override for photo
  @include mobile {
    padding-left: 0rem;
    padding-right: 0rem;
  }

  div.photo {
    background: rgba(0, 0, 0, 0.2);
    background-image: url("/images/purrble-flora-b.jpg");
    @include mobile {
      background-image: url("/images/purrble-flora-bd.jpg");
    }
    background-size: cover;
    background-position-x: right;
    background-position-y: 50%, bottom;
    @include mobile {
      background-position-y: 0%, bottom;
    }
  }

  h1.title.is-1 {
    font-size: 4.2rem;
    line-height: 5rem;
    font-weight: 900;
    @include mobile {
      font-size: 3rem;
    }
    @include tablet {
      font-size: 3.6rem;
    }
  }

  p.tagline {
    font-size: 2rem;
    font-weight: 600;
    @include mobile {
      font-size: 1.8rem;
    }
  }

  span.highlight {
    // background-color: rgba(30, 41, 22, 0.4);
  }

  div.description {
    h1,
    h2,
    p {
      color: white;
    }

    h2.title {
      margin-bottom: 0.5rem;
    }

    padding-bottom: 1rem;
    padding-left: 4rem;
    @include mobile {
      padding-top: 4rem; // Default
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  div.order-button {
    // padding-left: 6rem;
    padding-left: 4rem;
    padding-bottom: 4rem;
    @include mobile {
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }
  }

  button.order {
    cursor: pointer;
    background-color: $button-order-fill;
    border-radius: 0.8rem;
    border-style: none;
    margin-top: 0px;
    margin-bottom: 5px;
    box-shadow: 0px 5px 0px #835422;
    padding: 0.7rem 2rem 0.4rem 2rem;
    white-space: nowrap;
    // h4 {
    //   color: black;
    // }
  }

  button.order:hover {
    white-space: nowrap;
    margin-top: 3px;
    margin-bottom: 2px;
    box-shadow: 0px 2px 0px #835422;
  }

  div.photo-spacer {
    min-height: 105vh;
  }
}

section.sometimes-you-need-a-break {
  background-color: #fff;
  padding-top: 0;
}

section.let-purrble-help {
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;

  img.feature {
    max-width: 240px;
    width: 100%;
  }
}

section.how-purrble-works {
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;

  h2.title {
    margin-bottom: 0.5rem;
  }

  div.spacer {
    height: 3rem;
    @include mobile {
      height: 2rem;
    }
  }

  div.purrble-video {
    max-width: 600px;
    width: 100%;

    // FIXME Does this work to remove 1px border on video on iOS?
    video {
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
    }
  }
}

section.features {
  padding-top: 2rem;
  img.feature {
    max-width: 190px;
    width: 100%;
  }
}

section.calm-purrble {
  background-color: #fff;

  img {
    height: auto;
    max-width: 256px;
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
  }

  @include mobile {
    img {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
  }
}

section.unique-toy {
  background-color: $background-grey;
  // background-color: #fff;
  // padding-bottom: 1rem;
  img {
    width: 100%;
    max-width: 310px;
  }
}

section.w {
  background-color: white;
}

section.pre-order-header {
  padding-top: 2rem;
  background: rgb(240, 255, 255);
  background-image: url("../images/pre-order-adventure.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  background-position-x: 60%;
  // min-height: 75vh;
  min-height: 50vh;
  padding-top: 70px; // Header
  padding-bottom: 4rem;

  @include tablet {
    background-position-y: bottom;
    background-position-x: 50%;
    min-height: 55vh;
    // min-height: 75vh;
  }

  @include mobile {
    padding-top: 2.6rem;
    background-position-y: 50px;
    background-position-x: 30.5%;
    // min-height: 75vh;
    height: 120vh;
    max-height: 1100px;
  }

  img.logo {
    max-width: 200px;
    margin-top: -1.6rem;

    @include mobile {
      max-width: 160px;
      padding-bottom: 0.2rem;
    }
  }

  div.concept {
  }

  div.order-button {
    padding-top: 1.4rem;
    padding-bottom: 0.8rem;
    height: 70px;
    p {
      margin: auto;
    }
  }

  button.order {
    cursor: pointer;
    background-color: $button-order-fill;
    border-radius: 0.8rem;
    border-style: none;
    padding: 0.7rem 2rem 0.4rem 2rem;
  }

  button.order:hover {
    white-space: nowrap;
  }
}

section.pre-order-adventure {
  background: $background-grey;
  background-image: url("../images/purrble-adventure.png");
  background-position-x: 70vw;
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 0rem;

  @include tablet {
    padding-top: 8rem;
    // padding-bottom: 12rem;
  }

  @include mobile {
    background-size: 20rem;
    padding-bottom: 38rem;
    background-position-y: bottom;
    background-position-x: center;
  }
}

section.pre-order-stickers {
  background: $background-grey;
  padding-bottom: 4rem;

  div.stickers {
    background-image: url("../images/sticker-sheet.png");
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: cover;

    display: block;
    min-height: 40vh;
    margin-top: -4rem;
    margin-bottom: -4rem;

    // @include tablet {
    //   background-position-x: -200px;
    //   background-size: 75% auto;
    // }

    @include mobile {
      background-image: url("../images/sticker-sheet-m.png");
      background-size: cover;
      background-position-y: 65px;
      // background-position-y: bottom;
      background-position-x: right;
      margin-left: -16px;
      margin-right: -16px;
      min-width: 100vw;
      min-height: 450px;
      // min-height: 90vh;
      // min-height: 780px;
    }
  }
}

section.bring-purrble-home {
  padding-top: 0;
  padding-bottom: 0;
  background-image: url("../images/purrble-couch-la.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  div.photo-spacer {
    min-height: 900px;
  }

  div.product {
    // For total vertical column
    // background-color: rgba(255, 255, 255, 0.7);
    // background-color: rgba(255, 255, 255, 0.86);
    background-color: rgba(252, 252, 251, 0.93);
    border-radius: 3rem;
    padding: 3rem;
    @include mobile {
      padding: 2rem;
    }
  }

  div.concept {
    font-size: 1.2rem;
  }

  h2.title {
    margin-bottom: 2rem;

    @include mobile {
      margin-bottom: 2rem;
    }
  }

  h2.price {
    margin-bottom: 0rem;
  }

  p.backorder {
    strong {
      color: $text-color;
    }
  }

  p.discount-code {
    white-space: nowrap;
    color: $text-color-lighter;
    i {
      -moz-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      -o-transform: scale(-1, 1);
      -ms-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }
  }

  div.features {
    margin: auto;
    padding-left: 2.4rem;
  }

  li:before {
    content: "\2022";
    display: inline-block;
    width: 1rem;
    margin-left: -1rem;
  }

  button.order {
    cursor: pointer;
    background-color: $button-order-fill;
    border-radius: 0.8rem;
    border-style: none;
    padding: 0.7rem 2rem 0.4rem 2rem;
  }

  button.order:hover {
    white-space: nowrap;
  }

  img.amazon {
    max-width: 100%;
    width: 120px;
  }
}

section.adopt-a-purrble {
  background-color: beige;
  // padding-top: 0rem; // Override
  // padding-bottom: 0rem; // Override

  div.adopt {
    padding-top: 4rem; // Default
    padding-bottom: 4rem; // Default
    background-image: url("/images/adopt-purrble.jpg");
    background-size: cover;
    background-position-x: center;
    background-position-y: 30%, bottom;
    min-height: 70vh;
  }

  div.product {
    padding-left: 0;
    padding-right: 0;
  }

  span.adopt {
    display: table-cell;
    vertical-align: top;
    text-align: center;
    @include mobile {
      display: table;
      margin: auto;
      align-items: center;
      padding-bottom: 1rem;
    }
  }

  span.order {
    padding-left: 1rem;
  }

  img.adopt {
    border-radius: 3rem;
    width: 90%;
    max-width: 500px;
    // width: 400px;
  }

  div.concept {
    font-size: 1.2rem;
  }

  h2.title {
    margin-bottom: 2rem;

    @include mobile {
      margin-bottom: 2rem;
    }
  }

  h2.price {
    margin-bottom: 0rem;
  }

  div.features {
    margin: auto;
    padding-left: 2.4rem;
  }

  li:before {
    content: "\2022";
    display: inline-block;
    width: 1rem;
    // margin-left: -1rem;
    margin-right: 0.2rem;
  }

  button.order {
    cursor: pointer;
    background-color: $button-order-fill;
    border-radius: 0.8rem;
    border-style: none;
    margin-top: 0px;
    margin-bottom: 5px;
    box-shadow: 0px 5px 0px #835422;
    padding: 0.7rem 2rem 0.4rem 2rem;
    white-space: nowrap;
  }

  button.order:hover {
    white-space: nowrap;
    margin-top: 3px;
    margin-bottom: 2px;
    box-shadow: 0px 2px 0px #835422;
  }

  img.amazon {
    max-width: 100%;
    width: 120px;
  }
}

section.reviews {
  padding-top: 0;
  padding-bottom: 6rem;

  div.review-container {
    border-radius: 2rem;
    background-color: $background-grey;
    // border-radius: 0.8rem;
    // border: solid;
    // border-width: 1px;
    border-color: #c2c2c2;
    height: 100%;
    overflow: hidden;

    div.review {
      padding: 2rem 1rem 1rem 1rem;
    }

    p {
      font-size: 1.3rem;
    }

    span.review {
      display: table;
      // border-radius: 2rem;
      // background-color: rgba(228, 221, 215, 0.4);
    }
    span.review-content {
      display: table-cell;
      padding-left: 0.5rem;
      padding-right: 1rem;

      span.quote {
        font-size: 4rem;
        line-height: 1rem;
        vertical-align: bottom;
      }
    }

    div.photo {
      // border-radius: 2rem;
      padding: 0;
      background-image: url("/images/reviews/em_hanley.jpg");
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
      @include mobile {
        height: 300px;
        background-position-y: top;
      }
    }

    img.stars {
      width: 160px;
    }

    img.review {
      height: 400px;
      width: 400px;
      overflow: hidden;
    }

    img.reviewer {
      display: block;
      height: 60px;
      width: 60px;
    }

    span.reviewer {
      display: table;
      padding-top: 0.8rem;
      margin: auto;
    }
    span.reviewer-detail {
      display: table-cell;
      vertical-align: middle;

      p {
        font-size: 1.3rem;
        line-height: 1.8rem;
      }
    }
    span.photo {
      img {
        width: 60px;
      }
    }
    span.name {
      padding-left: 0.8rem;
    }
    span.platform {
      display: inline-block;
      padding-top: 1.2rem;
      padding-right: 1.2rem;

      a {
        font-size: 2.6rem;
        line-height: 1rem;
        color: rgba(255, 255, 255, 0.4);
      }
      a:hover {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}

section.born-from-research {
  background-color: #fff;
  // padding-top: 1rem;
  div.column {
    padding-bottom: 0rem;
  }
  div.stat {
    // border-radius: 50%;
    // width: 120px;
    // height: 120px;
    margin: auto;
    padding-bottom: 0.8rem;
    display: flex;
    align-items: center;
    h1 {
      // color: #fff;
      margin: auto;
    }
  }
  div.stat-1 {
    // background-color: #CC5769;
    h1 {
      color: $header-teal;
    }
  }
  div.stat-2 {
    // background-color: #27A26A;
    h1 {
      color: $header-teal;
      @include mobile {
        margin-top: 1rem;
      }
    }
  }

  img {
    padding: 2rem;
    vertical-align: middle;
    width: 254px;
    max-width: 100%;

    @include mobile {
      margin: auto;
      padding: 0;
      width: 100%;
      max-width: 150px;
    }
  }
}

section.calm-kids-better-days {
  // background-color: $background-grey;
  background-color: #fff;
  padding-top: 0;
  // padding-bottom: 0rem;

  h2 {
    span.underline {
      text-decoration: underline;
      text-underline-position: under;
    }
  }

  div.calm-kids-better {
    padding-right: 0;
  }

  div.days {
    padding-left: 0;
  }

  span.hidden {
    font-size: 2.5rem;
    font-weight: 600;
  }

  li {
    font-size: 2.3rem; //2.5rem matches h2 above
  }

  // Color the list
  li:nth-child(1) {
    // color: #575263;
    color: rgba(77, 72, 88, 1);
  }
  li:nth-child(2) {
    // color: #75707E;
    color: rgba(77, 72, 88, 0.86);
  }
  li:nth-child(3) {
    // color: #9E9BA5;
    color: rgba(77, 72, 88, 0.72);
  }
  li:nth-child(4) {
    // color: #B8B6BD;
    color: rgba(77, 72, 88, 0.58);
  }
  li:nth-child(5) {
    // color: #CECDD1;
    color: rgba(77, 72, 88, 0.44);
  }
  li:nth-child(6) {
    // color: #E7E6E7;
    color: rgba(77, 72, 88, 0.3);
  }
}

section.founding-story {
  background-color: $background-grey;
  padding-bottom: 0;
  a {
    color: $text-color;
  }
  a:hover {
    text-decoration: underline;
    color: black;
    transition: color linear 80ms;
  }
  img {
    padding: 2rem;
    vertical-align: middle;
    width: 254px;
    max-width: 100%;

    @include mobile {
      margin: auto;
      padding: 0;
      width: 100%;
      max-width: 150px;
    }
  }
}

section.media-kit {
  background-color: $background-grey;
  padding-top: 0.5rem;
  a {
    color: $text-color; // darken
    text-decoration: underline;
  }
  a:hover {
    color: black;
    text-decoration: underline;
    transition: color linear 80ms;
  }
}

section.order {
  button.order {
    cursor: pointer;
    background-color: $button-order-fill;
    border-radius: 0.8rem;
    border-style: none;
    //border-color: $button-order-border;
    margin-left: 1.5rem;
    // filter: drop-shadow(0.1rem 0.1rem 0.1rem $button-dropshadow);
    @include tablet {
      margin-right: 0.5rem;
    }
    @include mobile {
      margin-right: 0.5rem;
    }
    padding: 0.7rem 2rem 0.4rem 2rem;
  }

  button.order:hover {
    // transform: perspective(1px) translateZ(0);
    // transform-origin: center bottom;
    // transition: transform 100ms cubic-bezier(0.15, 0.51, 0.25, 1) 0s;
    white-space: nowrap;
  }
}

section.adventure-header {
  background-color: $adventure-header;
  padding-bottom: 0;
  white-space: nowrap;
  @include tablet {
    white-space: initial;
  }
  @include mobile {
    white-space: initial;
  }

  div.header {
    padding: 0;
  }

  span.header {
    display: table;
    margin: auto;
  }
  span.header-element {
    display: table-cell;
  }
  span.purrble {
    width: 200px;
    @include mobile {
      width: 100%;
    }
    padding-left: 2rem;
    padding-right: 2rem;
    vertical-align: bottom;
    @include mobile {
      margin-left: auto;
      margin-right: auto;
    }
    img.purrble {
      width: 100%;
      @include mobile {
        width: 136px;
      }
      vertical-align: bottom;
    }
  }
  span.title {
    padding-bottom: 1rem;
    img.logo {
      width: 200px;
      padding-bottom: 1rem;
    }
  }
  span.spacer {
    width: 200px;
    padding-left: 2rem;
    padding-left: 2rem;
  }

  h2 {
    color: white;
  }
}

section.adventure {
  background-color: #fffaf2;
  // background-image: url("/images/adventure/map/path-2.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position-y: bottom;

  @include mobile {
    background-color: #fffaf2;
    // background-image: url("/images/adventure/map/path-m.png");
    background-size: 100% auto; // TODO looks better as auto 100%, but needs gaps to be filled
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
  }

  h1.title,
  h2.title,
  h2.subtitle,
  h3.title,
  h3.subtitle,
  h4.title,
  h4.subtitle,
  li,
  p {
    color: $text-color-adventure;
  }

  p {
    line-height: 1.5em;
    margin-bottom: 1em;
  }
  p:last-child {
    margin-bottom: 0;
  }

  img.logo {
    display: block;
    margin: auto;
    padding-left: 10px;
    max-width: 280px;
    padding-bottom: 2rem;
  }
  h2.title {
    color: $purrble-teal;
  }

  span.map {
    margin: auto;
    display: table;
    @include mobile {
      display: block;
    }
  }

  span.map-part {
    display: table-cell;
    @include mobile {
      display: block;
    }

    position: relative;
    top: 0;
    left: 0;

    img.map {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 300px;
      z-index: 1;
    }

    img.lock {
      // opacity: 0.7;
    }

    img.question {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    img.question:hover {
      cursor: pointer;
    }
  }

  span.adventure-table {
    display: table;
    @include mobile {
      display: block;
    }
    @include tablet {
      display: block;
    }
  }
  span.adventure-row {
    display: table-row;
    @include mobile {
      display: block;
    }
    @include tablet-only {
      display: block;
    }
  }
  span.adventure-cell {
    display: table-cell;
    @include mobile {
      text-align: center;
      display: block;
    }
    @include tablet-only {
      text-align: center;
      display: block;
    }
    vertical-align: middle;

    img.adventure {
      max-width: 400px;
      @include mobile {
        width: 100%;
        max-width: 400px;
      }
      @include tablet-only {
        width: 100%;
        margin: auto;
        max-width: 500px;
      }
      @include desktop {
        margin-left: -2em;
        padding-right: 2em;
      }
    }
  }
  span.field-notes {
    background-color: $adventure-minimap-background;
    width: 100%;
    min-height: 120px;
    padding-left: 80px;
      padding-right: 80px;
    @include mobile {
      padding-left: 40px;
      padding-right: 40px;
    }
    padding-top: 2em;
    padding-bottom: 2em;
    border-radius: 15px;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 4rem;

    p {
      display: block;
    }
    p.title {
      font-size: 1.6em; //18px;
      line-height: 1.6em;
      margin-bottom: 0;
    }

    span.adventure-cell {
      display: block;
    }

  }
  
  div.spacer-before-footer {
    @include tablet-only {
      height: 20vh;
    }
    @include desktop-only {
      height: 22vh;
    }
    @include widescreen-only {
      height: 16vh;
    }
    @include fullhd {
      height: 12vh;
    }
  }
}

// Animation for locked adventure maps
//
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes wiggle {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

span.shake {
  animation: wiggle 0.74s step-end;
}

section.adventure-to-be-continued {
  background-color: $adventure-minimap-background;
  
  h2.title {
    color: $text-color-adventure;
  }

  span.map {
    display: table;
    // @include mobile {
    //   display: block;
    // }
  }

  span.map-part {
    display: table-cell;

    position: relative;
    top: 0;
    left: 0;

    img.map {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 300px;
      z-index: 0;
    }

    img.question {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    img.question:hover {
      cursor: pointer;
    }
  }

}

section.error-page {
  padding-bottom: 20rem;

  div.error-header {
    height: 70px;
    padding-bottom: 8rem;
  }

  img.logo {
    max-width: 12rem;
  }

  img.lost-purrble {
    max-width: 400px;
  }

  a {
    font-size: 1.4rem;
  }
}

header.main {
  height: 70px;
  position: fixed;
  // top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background-color: #fff;
  transition: top 0.2s ease-in-out;

  h2 {
    color: $header-yellow;
  }

  h3 {
    color: $header-yellow;
  }

  div.logo {
    height: 70px;
    padding: 0;
    display: block;
    @include mobile {
      display: none;
    }
  }

  div.order-button {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    height: 70px;
    p {
      margin: auto;
    }
  }

  button.order-header {
    cursor: pointer;
    background-color: $button-order-fill;
    border-radius: 0.8rem;
    border-style: none;
    //border-color: $button-order-border;
    margin-left: 1.5rem;
    // filter: drop-shadow(0.1rem 0.1rem 0.1rem $button-dropshadow);
    @include tablet {
      margin-right: 0.5rem;
    }
    @include mobile {
      margin-right: 0.5rem;
    }
    padding: 0.7rem 2rem 0.4rem 2rem;
  }

  button.order-header:hover {
    // transform: perspective(1px) translateZ(0);
    // transform-origin: center bottom;
    // transition: transform 100ms cubic-bezier(0.15, 0.51, 0.25, 1) 0s;
    white-space: nowrap;
  }
}

div.modal {
  div.modal-card {
    border-radius: 2rem;
    @include tablet {
      width: 65%;
      max-width: 1000px;
      min-width: 640px;
    }
    @include mobile {
      border-radius: 0;
    }
  }

  div.modal-card-head {
    background: white;
    border-bottom: none;
    @include desktop {
      padding: 4rem 4rem 0 4rem;
    }
    @include tablet {
      padding: 2rem 2rem 0 2rem;
    }
    @include mobile {
      padding: 2rem 2rem 0 2rem;
    }
    button.delete {
      height: 30px;
      max-height: 30px;
      max-width: 30px;
      min-height: 30px;
      min-width: 30px;
    }
  }

  div.modal-card-body {
    @include desktop {
      padding: 4rem;
    }
    @include tablet {
      padding: 2rem;
    }
  }

  h2.title.is-2 {
    margin-bottom: 0;
  }

  img.purrble {
    max-width: 200px;
    padding-right: 1rem;
    @include mobile {
      max-width: 40%;
      padding: 0;
    }
  }

  p {
    a {
      color: $text-color;
    }
    a:hover {
      background-color: $button-order-fill;
    }
  }

  form {
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
    input {
      font-size: 1.4rem;
      margin-bottom: 0.5rem;
      width: 100%;
      padding: 0.7rem 1rem 0.4rem 1rem;
      border-radius: 0.8rem;
      border-width: 1px;
      border-color: $text-color-lighter;
      -webkit-appearance: none;
    }
    button {
      font-size: 1.4rem;
      cursor: pointer;
      background-color: $button-order-fill;
      border-radius: 0.8rem;
      border-style: none;
      padding: 0.7rem 2rem 0.4rem 2rem;
      margin-top: 0.5rem;
    }

    p.sendfox-message {
      margin-top: 0.8rem;
    }
  }
}

header.nav-up {
  top: -70px;
}

header.nav-down {
  top: 0;
}

footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $footer-color;

  img.logo {
    max-width: 6rem;
  }

  ul {
    list-style-type: none;

    li {
      font-size: 1rem;

      strong {
        color: white;
        font-weight: 900;
      }

      a {
        color: white; //$text-off-white;
      }

      a:hover {
        color: white;
        text-decoration: underline;
        transition: color linear 80ms;
      }
    }
  }

  .made-with-love {
    margin-top: 4rem;

    color: $text-off-white;

    img {
      width: 4rem;
      height: auto;
      margin-bottom: -0.25rem;
      margin-left: -0.25rem;
    }

    // a.sproutel-logo:hover {
    //   img {
    //     content: url("../images/sproutel-logo-white-2x.png");
    //   }
    // }
  }

  .copyright {
    p {
      color: $text-off-white;
      font-size: 0.8em;
    }
  }
}
footer.adventure {
  background-color: $adventure-footer;
  
  .made-with-love {
    color: $adventure-footer-text;
  }
  
  .copyright {
    p {
      color: $adventure-footer-text;
    }
  }
}